// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plotly .draglayer.cursor-crosshair {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ActivityTimeline/Timeline.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".plotly .draglayer.cursor-crosshair {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
